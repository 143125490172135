<template>
  <div class="medicine_container" :style="`background: ${pageData.bgcolor}`">
    <div v-if="banner" class="img">
      <img :src="banner" alt="" />
    </div>
    <div class="content">
      <!-- <div class="cate-type">
        <div v-for="(item, index) in pageData.cate" :key="index" @click="chooseItem(item, index)" :class="['cate-item', activeInd == index ? 'active-color' : '']">
          <img :src="item.logo" class="cate-img" alt="" />
        </div>
      </div> -->
      <div class="page-title">
        控销专区：
        <div :class="['page-tab', activeInd == index ? 'active' : '']" @click="chooseItem(item, index)" v-for="(item, index) in pageData" :key="index">{{ item.title }}</div>
      </div>
      <div class="card-box" v-for="item in pageData" :key="item.id" :id="item.id">
        <div class="goods-title">
          <p class="cate-title">{{ item.title }}</p>
          <div class="goods-num" @click="toGoods(item)">
            <div class="goods-count">共{{ item.counts }}个商品></div>
            <span class="to-goods">点击前往专场</span>
          </div>
        </div>
        <div class="item_wrap">
          <div class="item" v-for="sub in item.goods" :key="sub.id">
            <bigimg :itemData="sub" />
          </div>
        </div>
        <!-- <div class="empty">
          <el-empty v-if="!list.length" :image-size="200"></el-empty>
        </div> -->
      </div>
    </div>

    <!--左侧导航  -->
    <!-- <floornav :list="list" /> -->
  </div>
</template>

<script>
import bigimg from "@/components/goodsItem/bigImg.vue";
// import floornav from "@/components/floornav.vue";
import { handleJump } from "@/utils";
export default {
  components: {
    // item,
    bigimg,
    // floornav,
  },
  data() {
    return {
      handleJump,
      banner: "",
      pageData: {},
      list: [],
      info: {},
      typeTitle: "",
      activeInd: 0,
      zone_id: "",
      kw: "",
      page: 1,
      page_size: 20,
      total: 0,
      //   form: {
      //   zone_id: "",
      //   },
    };
  },
  created() {
    // console.log("地址栏参数-----", this.$route.query.zone_id);
    this.getBanner();
    this.getData();
    // if (this.$route.query.zone_id) {
    //   this.zone_id = this.$route.query.zone_id;
    //   this.getData();
    //   this.getGoodsList();
    // }
    // getZoneInfo(this.$route.query.value).then((res) => {
    //   this.info = res;
    // });
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll); // 监听页面滚动
  },
  methods: {
    handleScroll() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      // if (scrollTop >= 580) {
      //   this.show = true;
      // } else {
      //   this.show = false;
      // }

      let offsetTopArr = [];
      this.pageData.forEach((v) => {
        // console.log(v.id);
        offsetTopArr.push(document.getElementById(v.id).offsetTop);
      });
      // console.log("距离---", offsetTopArr);
      let navIndex = 0;
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是 n 了
        if (scrollTop >= offsetTopArr[n] - 400) {
          navIndex = n;
        }
      }

      this.activeInd = navIndex;
    },
    getBanner() {
      this.$api("zone.toolsBanner", { type: 2 }).then((res) => {
        this.banner = res.data.logo;
      });
    },
    getGoodsList() {
      this.$api("goods.getGoods", { zone_id: this.zone_id, page: this.page, page_size: this.page_size, kw: this.kw }).then((res) => {
        this.list = res.data.list;
        console.log("777----", this.list);
        this.total = res.data.count;
      });
    },
    getData() {
      this.$api("zone.controlData").then((res) => {
        // this.pageData = res.data;
        // let data = [
        //   {
        //     title: "活动1",
        //     goods: [
        //       {
        //         title: "测试商品1",
        //         max_num: "10",
        //         min_num: "1",
        //         step: "3",
        //         price: "6.66",
        //         sku: "10*5",
        //         line_price: "8.88",
        //         show_cart: 101,
        //         scqy: "测试测试",
        //       },
        //       {
        //         title: "测试商品2",
        //         max_num: "10",
        //         min_num: "2",
        //         step: "3",
        //         price: "6.66",
        //         sku: "10*5",
        //         line_price: "8.88",
        //         show_cart: 101,
        //         scqy: "测试测试",
        //       },
        //     ],
        //   },
        //   {
        //     title: "活动2",
        //     goods: [
        //       {
        //         title: "测试商品3",
        //         max_num: "10",
        //         min_num: "3",
        //         step: "3",
        //         price: "6.66",
        //         sku: "10*5",
        //         line_price: "8.88",
        //         show_cart: 101,
        //         scqy: "测试测试",
        //       },
        //       {
        //         title: "测试商品4",
        //         max_num: "10",
        //         min_num: "4",
        //         step: "3",
        //         price: "6.66",
        //         sku: "10*5",
        //         line_price: "8.88",
        //         show_cart: 101,
        //         scqy: "测试测试",
        //       },
        //     ],
        //   },
        // ];
        this.pageData = res.data.map((item) => {
          item.goods.map((v) => {
            this.$set(v, "number", v.min_num);
            return v;
          });
          return item;
        });
      });
    },
    toGoods(data) {
      this.$store.commit("CHANGE_TAB", 1);
      this.$router.push({
        path: "/index/allGoods",
        query: { control_id: data.id },
      });
    },
    chooseItem(data, ind) {
      this.activeInd = ind;
      document.getElementById(data.id).scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    },
    handleMore(item) {
      //   console.log("中药专区----", item);
      let firstTab = this.$store.state.pageName;
      //   console.log("面包屑---", firstTab);
      this.$store.commit("CHANGE_PAGENAME", [firstTab[0], item.title]);
      if (item.url.url == "/index/allGoods") {
        this.$store.commit("CHANGE_TAB", 1);
      }
      handleJump(item.url);
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.getGoodsList();
    },
    handleClick() {
      this.page = 1;
      this.getGoodsList();
    },
    handleEnter() {
      this.page = 1;
      this.getGoodsList();
    },
  },
};
</script>

<style lang="less" scoped>
.medicine_container {
  background-size: cover;
  // background: #f8f7f1;
  padding-bottom: 20px;
}
.img {
  width: 100%;
  height: 375px;
}

.item_wrap {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  .item {
    margin-right: 12px;
  }
  .item:nth-child(5n) {
    margin-right: 0;
  }
}
.page-title {
  height: 60px;
  display: flex;
  margin-top: 12px;
  align-items: center;
  box-shadow: 0 0 5px 2px #ccc;
  border-radius: 10px;
  padding: 0 10px;
  margin-bottom: 20px;
  position: sticky;
  display: flex;
  top: 0;
  z-index: 9;
  background-color: #fff;
  .page-tab {
    font-size: 14px;
    color: #444;
    cursor: pointer;
    margin-right: 15px;
  }
  .active {
    color: #fff;
    background-color: #198c8b;

    padding: 2px 5px;
    border-radius: 5px;
  }
}

.card-box {
  width: 100%;
  box-shadow: 0 0 5px 2px #ddd;
  border-radius: 12px;
  margin-top: 10px;
  overflow: hidden;
  .goods-title {
    // width: 100%;
    height: 70px;
    background: linear-gradient(45deg, #f84032 5%, #fff);
    padding: 0 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .cate-title {
      font-size: 30px;
      color: #fff;
    }
    .goods-num {
      cursor: pointer;
      .goods-count {
        color: #f74b4b;
        font-size: 18px;
      }
      .to-goods {
        color: #e64ff5;
        font-size: 13px;
      }
    }
  }
}
</style>
